<template>
    <div>
        <div class="demo-spin-article">
            <div class="row">
                <div class="col-md-6 mb-3">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyAllowance.cycleYear')
                    }}</label>
                    <date-Picker
                        :value="model.cycle_year"
                        type="year"
                        format="yyyy"
                        placeholder="Select Year"
                        style="width: 233px"
                        @on-change="cycleYearChange"
                        :class="{
                            'is-invalid': errors.has('cycle_year')
                        }"
                    >
                    </date-Picker>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('cycle_year')"
                    >
                        {{ errors.first('cycle_year') }}
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <label class="col-label form-label required tw-text-xs">{{
                        $t('monthlyAllowance.cycleMonth')
                    }}</label>
                    <div>
                        <date-Picker
                            :value="model.cycle_month"
                            type="month"
                            placeholder="Select Month"
                            style="width: 233px"
                            format="MM"
                            @on-change="cycleMonthChange"
                            :class="{
                                'is-invalid': errors.has('cycle_month')
                            }"
                        >
                        </date-Picker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('cycle_month')"
                        >
                            {{ errors.first('cycle_month') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12 mb-3">
                    <label
                        for="file"
                        class="tw-border tw-p-2 tw-rounded-md tw-cursor-pointer"
                        >Browse File</label
                    >
                    <input
                        type="file"
                        ref="file"
                        id="file"
                        class="tw-hidden"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        @change="onFileChange"
                    />
                    <div class="validate" v-if="errors.has('file')">
                        {{ errors.first('file') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    {{ display_name }}
                </div>
            </div>
        </div>
        <div>
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t('cancel') }}
                </ts-button>
                <ts-button
                    color="primary"
                    :waiting="waiting"
                    :disabled="waiting"
                    @click.prevent="onSave"
                >
                    {{ $t('save') }}
                </ts-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
export default {
    name: 'import-monthly-incentive-bonus-form',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            display_name: null,
            file: undefined,
            model: {
                cycle_year: null,
                cycle_month: null
            }
        }
    },
    methods: {
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('cycle_year', this.model.cycle_year)
            formData.append('cycle_month', this.model.cycle_month)
            this.$store
                .dispatch('payroll/monthlyIncentiveBonus/importExcel', formData)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onFileChange (e) {
            this.display_name = null
            this.file = e.target.files[0]
            this.display_name = this.file ? this.file.name : null
        },
        cycleYearChange(value) {
            this.model.cycle_year = value
        },
        cycleMonthChange(value) {
            this.model.cycle_month = value
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'MONTHLY INCENTIVE BONUS',
                desc: not.text
            })
        }
    }
}
</script>

<style lang="scss" scoped></style>
